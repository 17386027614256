@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");
@media screen and (max-width: 768px) {
  .resultsGrid, .requestsGrid {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important;
  }
}
@media only screen and (max-width: 1024px) {
  .logo {
    width: 310px;
    margin: 27px 0em !important;
    font-size: 2rem !important;
    align-self: center;
    flex-direction: column !important;
  }
  .logoImg {
    height: 80px !important;
  }
  .dashboardContainer {
    width: 98vw !important;
  }
  #logo {
    flex-direction: column !important;
    align-items: center !important;
    margin-top: 0 !important;
    position: relative;
    margin-bottom: 2em;
  }
  .authFormContainer {
    min-width: 300px !important;
    width: 90vw;
  }
  .uploadFormDiv {
    min-width: 300px !important;
    width: 90vw !important;
    top: 45% !important;
  }
  .filesHeader {
    flex-direction: column !important;
    align-items: stretch !important;
    gap: 10px !important;
  }
  .fileTypesContainer {
    flex-direction: column !important;
    gap: 0 !important;
  }
  .dashButtons {
    justify-content: center !important;
    align-items: center !important;
  }
  .dashTabs {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: stretch !important;
    gap: 0.75em !important;
    padding: 5px;
    padding: 1.2em 1em;
    border: 0 !important;
    background: #6d6d6d3b !important;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
  }
  .dashTabs button:hover {
    background: #333333c4 !important;
  }
  .dashTabs button {
    font-size: 1.2em !important;
    margin-right: 0 !important;
    border-radius: 0 !important;
    padding: 0.5em 0 !important;
  }
  .tabsContent {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .dashTabs .active {
    background: transparent !important;
    font-weight: 700 !important;

  }
  .dashTabs :not(.active) {
    font-weight: 500 !important;
  }
  .plexTab {
    margin-left: 0 !important;
  }
  .button {
    font-size: 1rem !important;
    padding: 10px 0.8em !important;
  }
  .yearNode h3,
  .monthNode h4,
  .dayNode {
    margin-left: 0 !important;
  }
  .plexResults {
    flex-direction: column !important;
    padding: 5px !important;
  }
  .plexPosterDetails {
    flex-direction: column !important;
  }
  .plexSelector {
    width: 100%;
    align-items: flex-start;
  }
  .plexDetails {
    padding: 0.5em;
  }
  .plexMoreDetails {
    width: 100%;
    align-items: stretch !important;
  }
  .plexButtons {
    margin-top: 0 !important;
  }
  .boxOffice {
    align-items: flex-start !important;
  }
  .button.plexSelect.request {
    font-size: 1.5rem;
    width: 100%;
  }
  .plexPoster,
  .plexPoster.more {
    width: 100% !important;
    object-position: top;
  }
  .plexPosterRecentAdded {
    object-fit: cover !important;
    object-position: center;
    height: 222px !important;
    width: 100% !important;
  }
  .imdbSearch {
    flex-direction: column !important;
  }
  .searchYear {
    width: 100% !important;
    max-width: unset !important;
  }
  .searchButtons {
    gap: 5px !important;
    align-items: stretch !important;
    width: 100% !important;
  }
  .plexPosterDetailsRecent {
    flex-direction: column !important;
    padding: 5px;
  }
  .plexHeader {
    font-size: 1.2rem !important;
    margin: 1em 0 !important;
    border-bottom: 1px dotted #e5a10de0 !important;
    padding-bottom: 0.5em !important;
  }
  .title {
    font-size: 1rem !important;
  }
  .mobileTabMenu {
    display: flex !important;
    padding-bottom: 5px;
  }
  .navButtons {
    flex-direction: column !important;
  }
  .recentGrid {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
  }
  .plexRequestHeader {
    flex-direction: column !important;
  }
}

.toggleMobileTabs{
  background: #000000c4;
  border: 0;
  outline: 0;
  padding: 0.50em 0.75em;
  padding-top: 0.70em;
  font-size: 1.1em;
  color: #ffffff88;
  cursor: pointer;
  border-radius: 5px;
}
.mobileTabMenu{
  display: none;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.mobileTabMenu div:nth-child(1) {
  font-size: 1.2em;
}
.closed {
  display: none !important;
}

:root {
  color-scheme: dark;
}

* {
  box-sizing: border-box;
  
}

html,
body {
  background: radial-gradient(
    circle,
    rgb(0, 0, 0) 0%,
    rgb(19, 19, 19) 60%,
    rgb(27, 27, 27) 100%
  );
  font-family: "Source Code Pro", monospace;
  color: #ffffff88;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}

.filesContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100vh;
}

.dashboardContainer {
  width: 70vw;
}

.fileAttribs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 1em;
  white-space: nowrap;
}

.fileAttribs div:nth-child(1) {
  font-size: 1rem;
  font-weight: 500;
  color: #627e9db9;
}

.fileAttribs div:nth-child(2) {
  font-size: 0.8rem;
  font-weight: 300;
}

.fileDeets {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
}

.fileButtonsContainer {
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: stretch;
}

h2 {
  margin-bottom: 0;
}

#logo {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  user-select: none;
}

.logo {
  font-weight: 900;
  font-size: 3rem;
  color: #ffffff71;
  margin: 0.8em 0;
  cursor: pointer;
  user-select: none;
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  text-transform: uppercase;
  text-shadow: 2px 0 5px #4646469f, 0 0 20px #afafaf8c;
  /* text-shadow: #ffffff86 0 0 5px; */
  transition: transform 0.125s ease-in-out;
}

.logoImg {
  width: auto;
  height: 72px;
}

.logo:hover {
  transform: scale(1.05);
  filter: brightness(1) saturate(1) drop-shadow(0 0 5px #020202);
}

.logo:active {
  transform: scale(0.95);
}

.navButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.navButtons div:nth-child(1) {
  color: #b3b44c;
}

.navButtons div:nth-child(2) {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.dashButtons {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  z-index: 1;
}

.button {
  font-family: "Source Code Pro", monospace;
  letter-spacing: -1px;
  display: flex;
  background: #000000c4;
  backdrop-filter: blur(5px);
  border-radius: 3px;
  border: 0;
  box-sizing: border-box;
  color: #ffffff88;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px 0.8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  transition: all 0.125s ease;
  align-content: center;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
.button.copyLink {
  background: transparent;
  border: #53535388 1px solid;
  color: #ffffff88;
}
.button.downloadFile {
  border: #53535388 1px solid;
  background: transparent;
  color: #ffffff88;
  flex-grow: 1;
}
.button.deleteFile {
  border: 1px solid #c26b6b7c;
  color: #c26b6b7c;
}
.button.plexSelect:not(.inLibraryBtn) {
  background: #e5a10d4d;
  color: #e5a00d;
  border: 1px solid #e5a10d;
  font-weight: 500;
  font-size: 16px;
  padding: 1.2em 1em;
}
.button.plexSelect.request:not(.inLibraryBtn) {
  background: #e5a10d1a;
  border: 1px solid #7256198a;
  padding: 0.75em 0.8em;
  font-size: 1.1rem;
  transition: background-color 0.5s ease;
}
.button.plexSelect.request:not(.inLibraryBtn):hover {
  background-color: #e5a10d71;
}
.button.plexDetailsBtn {
  background: transparent !important;
  border: #7256198a 1px solid;
  color: #e5a10d8a;
  font-weight: 500;
  font-size: 16px;
  min-width: 150px;
}

.searchBtn {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.uploadFormDiv .button {
  border: 1px solid #ffffff31 !important;
}

.authFormFields .button {
  border: 1px solid #ffffff71 !important;
}

.button:hover:not(.downloadFile):not(.copyLink):not(.deleteFile):not(
    .plexSelect
  ):not(.plexDetailsBtn) {
  filter: brightness(1.2) saturate(1.5);
  box-shadow: 0 0 0 2px #ffffff71;
}

.button:focus:not(.downloadFile):not(.copyLink):not(.deleteFile):not(
    .plexSelect
  ):not(.plexDetailsBtn) {
  filter: brightness(1.2) saturate(1.5);
  box-shadow: 0 0 0 2px #ffffff71;
}

.button.deleteFile:hover,
.button.deleteFile:focus {
  filter: brightness(1.2) saturate(1.5);
  box-shadow: 0 0 0 1px #c26b6b7c;
}

.button.plexDetailsBtn:hover,
.button.plexDetailsBtn:focus {
  filter: brightness(1.2) saturate(1.5);
  box-shadow: 0 0 0 1px #e5a10dc4;
  color: #e5a00dc4;
}

.button.plexSelect:hover,
.button.plexSelect:focus {
  filter: brightness(1.2) saturate(1.5);
  box-shadow: 0 0 0 1px #e5a00d;
}

.button.downloadFile:hover,
.button.downloadFile:focus,
.button.copyLink:hover,
.button.copyLink:focus {
  filter: brightness(1.2) saturate(1.5);
  font-weight: 500;
  box-shadow: 0 0 2px 1px #ffffff88;
}

.button:active {
  box-shadow: none;
  transform: translateY(5px);
}

@keyframes clouds-loop-1 {
  to {
    background-position: -1000px 0;
  }
}

.clouds-1 {
  background-image: url("../public/assets/clouds_2.webp");
  animation: clouds-loop-1 20s infinite linear;

}

@keyframes clouds-loop-2 {
  to {
    background-position: -1000px 0;
  }
}

.clouds-2 {
  background-image: url("../public/assets/clouds_1.webp");
  animation: clouds-loop-2 75s infinite linear;
}

@keyframes clouds-loop-3 {
  to {
    background-position: -1579px 0;
  }
}

/* keyframe to change breathe filter brightness effect */
@keyframes breathe {
  0% {
    filter: brightness(0.5) sepia(22%) saturate(1);
    opacity: 0.4;
  }
  25% {
    filter: brightness(0.7) sepia(45%) saturate(1);
    opacity: 0.7;
  }
  50% {
    filter: brightness(1) sepia(100%) saturate(1);
    opacity: 1;
  }
  75% {
    filter: brightness(0.7) sepia(45%) saturate(1);
    opacity: 0.7;
  }
  100% {
    filter: brightness(0.5) sepia(22%) saturate(1);
    opacity: 0.4;
  }
}

.clouds-3 {
  background-image: url("../public/assets/clouds_3.png");
  animation: clouds-loop-3 77s infinite linear;
}

.clouds {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  filter: brightness(0.5) saturate(0);
  animation: breathe 60s infinite;
}

.clouds-1,
.clouds-2,
.clouds-3 {
  background-repeat: repeat-x;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 500px;
}

.uploadFormDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  gap: 10px;
  align-items: center;
  background: #b1b1b12e;
  backdrop-filter: blur(5px);
  align-content: space-between;
  border-radius: 5px;
  border: 1px solid #ffffff2f;
  justify-content: space-between;
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  min-width: 500px;
}

.uploadFormFields {
  display: flex;
  flex-direction: column;
  padding: 1em;
  gap: 10px;
  border-radius: 5px;
  width: 100%;
  align-items: center;
}

.uploadControlsDiv {
  display: flex;
  flex-direction: column;
  gap: 2em;
  justify-content: space-around;
  width: 100%;
  align-items: flex-end;
  padding: 5px;
}

.fileInput {
  display: none;
}

.fileDetailsBox {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 3px;
  background: #00000071;
  border: 1px dotted #ffffff0a;
  gap: 1.5em;
  justify-content: space-between;
  transition: all 0.2s ease;
  animation: scaleInCenter 0.5s ease;
}

.fileDetailsBox:hover {
  border: 1px dotted #ffffff71;
}

.fileDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 75px;
  justify-content: flex-start;
}

.fileTopContainer div:nth-child(1) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: 900;
  cursor: default;
}

.fileTopContainer {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.fileTopContainer div:nth-child(1) {
  color: #ffffff88;
}

.filesHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
  gap: 1em;
}

.filesHeader > div:nth-child(1) {
  flex-grow: 1;
}

.filesHeaderText {
  font-weight: 700;
  font-size: 1.5em;
}

.fileTypesContainer {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.fileTypeIconContainer > div {
  font-weight: 500;
  font-style: italic;
}

.fileTypesText {
  font-weight: 900;
  font-size: 12px;
}

.fileTypesText span:nth-child(2) {
  font-weight: 200;
}

.authFormContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  min-width: 425px;
}

.authFormFields {
  display: flex;
  flex-direction: column;
  background: #b1b1b12e;
  backdrop-filter: blur(10px);
  border-radius: 5px;
  width: 100%;
  border: 1px solid #ffffff2f;
}

.formButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  justify-content: end;
}

.formHeader {
  font-family: 'Roboto', sans-serif;
  font-size:1.3rem;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.815);
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  border-bottom:#ffffff2f 1px solid;
  background-color: #000000c4;
  border-radius: 5px 5px 0 0;
  padding: 0.5em;
}

.formHeader div:nth-child(1) {
  font-size: 1rem;
  font-weight: 300;
}
.openReg {
  font-weight: 700;
  color: #6bc28ce0;
}
.closedReg {
  font-weight: 700;
  color: #c26b6be0;
}
.formContainer {
  padding: 1em;
}

.inputField {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 0.50em;
}

label {
  user-select: none;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 200;
  font-size: 1em;
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
  align-content: center;
  transition: all 0.2s ease;
  width: 100%;
}

label:hover {
  filter: brightness(1.2);
}

input {
  font-family: "Rajdhani", sans-serif;
  background: #00000071;
  border: 1px solid #ffffff21;
  border-radius: 3px;
  color: #b3b3b3;
  font-size: 1em;
  padding: 0.5em;
  outline: none;
  width: 100%;
}

select {
  font-family: "Rajdhani", sans-serif;
  background: #000000e3;
  border: 1px solid #ffffff21;
  border-radius: 3px;
  color: #b3b3b3;
  font-size: 1em;
  padding: 0.5em;
}

.requestCountDropdown {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 0.9rem;
}

input[type="checkbox"] {
  filter: brightness(2) invert(0.8);
  transform: scale(1.5);
  width: fit-content;
}

.fileGrids {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(322px, 1fr));
  gap: 10px;
}

.yearNode > h3 {
  user-select: none;
  cursor: pointer;
  color: #ffffff88;
}
.yearNode > h3:hover {
  filter: brightness(1.2);
}
.monthNode > h4 {
  user-select: none;
  cursor: pointer;
  color: #ffffff88;
}
.monthNode > h4:hover {
  filter: brightness(1.2);
}
.dayNode > h5 {
  user-select: none;
  cursor: pointer;
  color: #ffffff88;
}
.dayNode > h5:hover {
  filter: brightness(1.2);
}

.dashTabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dashTabs button {
  font-family: "Space Mono", monospace;
  background: #060606;
  backdrop-filter: blur(5px);
  border: 0;
  color: #cccccc88;
  font-size: 1.1em;
  padding: 0.75em;
  font-weight: 900;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-right: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tabButton:not(.active) {
  color: #a7a7a771;
  background: #0505059f;
}

.dashTabs button:hover {
  color: #ffffff88;
  background: #060606;
}

.tabsContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #060606;
  border-radius: 5px;
  border-top-left-radius: 0;
  padding: 1em;
}

.fileDetailsMimeType {
  font-size: 2.22rem;
}

.fileTypeIconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  user-select: none;
  padding-left: 1em;
  color: #ffffff88;
  white-space: nowrap;
}

.fileTypeIconContainer span {
  font-size: 12.22px;
}

.dayNode h5,
.monthNode h4,
.yearNode h3 {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  justify-content: space-between;
  border-bottom: 1px dotted #b3b3b357;
  padding-bottom: 0.5em;
  transition: all 0.2s ease;
}

.dayNode h5:hover,
.monthNode h4:hover,
.yearNode h3:hover {
  color: #ffffff88;
  border-bottom: 1px dotted #ffffff88;
}

@keyframes scaleInCenter {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.yearNode h3 {
  margin-bottom: 2em;
}

.monthNode h4 {
  margin-left: 3em;
  margin-bottom: 2em;
}

.dayNode {
  margin-left: 6em;
}

.countItemsText {
  font-weight: 300;
}

.myCodesContainer {
  display: flex;
  flex-direction: column;
  gap: .5em;
  align-items: stretch;
  align-content: flex-end;
  justify-content: center;
  width: 100%;
}

.usersInvitesContainer {
  align-content: center;
  /* align-items: center; */
  display: flex;
  flex-direction: row;
  padding: 0 2em;
  justify-content: space-between;
}

.myCodesContainer div:nth-last-child(1) {
  padding-bottom: 1em;
}

.codeBox {
  font-weight: 200;
  background: #050505da;
  padding: 2px 1em;
  border-radius: 5px;
  user-select: all;
}

.notUsed {
  color: #09ff00e0;
}

.used {
  color: #ff0000e0;
}

.inRequests {
    background: #f2ff3631;
    color: #eeff00;
    border: 1px solid #eeff00;
    padding: 2px 5px;
    font-size: 0.9rem;
    border-radius: 5px;
    white-space: nowrap;
    width: fit-content;
    user-select: none;
}

.inRequestsBtn:disabled:active {
  transform: none;
}

.inRequestsBtn:disabled:hover {
  filter: none;
  background: transparent;
  box-shadow: none;
}

.publicFile {
  color: #6bc28ce0;
  font-weight: 500;
  font-size: 0.9rem;
  padding-top: 1em;
}
.privateFile {
  color: #c26b6be0;
  font-weight: 500;
  padding-top: 1em;
  font-size: 0.9rem;
}

.plexTab {
  margin-left: 1em;
}

.categories {
  background: #b1b1b12e;
  backdrop-filter: blur(5px) sepia(22%);
  border-radius: 5px;
  padding: 0.5em;
  margin-top: 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bounce {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.letter {
  -webkit-animation: bounce 0.75s cubic-bezier(0.05, 0, 0.2, 1) infinite
    alternate;
  animation: bounce 0.75s cubic-bezier(0.05, 0, 0.2, 1) infinite alternate;
  display: inline-block;
  transform: translate3d(0, 0, 0);
  margin-top: 0.5em;
  text-shadow: rgba(0, 0, 0, 0.4) 0 0 0.05em;
  color: #ffffff88;
}
.letter:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.letter:nth-child(2) {
  -webkit-animation-delay: 0.0833333333s;
  animation-delay: 0.0833333333s;
}
.letter:nth-child(3) {
  -webkit-animation-delay: 0.1666666667s;
  animation-delay: 0.1666666667s;
}
.letter:nth-child(4) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.letter:nth-child(5) {
  -webkit-animation-delay: 0.3333333333s;
  animation-delay: 0.3333333333s;
}
.letter:nth-child(6) {
  -webkit-animation-delay: 0.4166666667s;
  animation-delay: 0.4166666667s;
}
.letter:nth-child(7) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.letter:nth-child(8) {
  -webkit-animation-delay: 0.5833333333s;
  animation-delay: 0.5833333333s;
}
.letter:nth-child(9) {
  -webkit-animation-delay: 0.6666666667s;
  animation-delay: 0.6666666667s;
}
.letter:nth-child(10) {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.letter:nth-child(11) {
  -webkit-animation-delay: 0.8333333333s;
  animation-delay: 0.8333333333s;
}

@keyframes bounce {
  0% {
    transform: translate3d(0, 0, 0);
    text-shadow: rgba(0, 0, 0, 0.4) 0 0 0.05em;
  }
  100% {
    transform: translate3d(0, -10px, 0);
    text-shadow: rgba(0, 0, 0, 0.4) 0 1em 0.35em;
  }
}

.tab-buttons {
  display: flex;
  flex-direction: row;
  gap: 5px;
  background: #3d3d3dc4;
}

.tab-buttons .button {
  border: 0 !important;
  background: transparent;
  backdrop-filter: unset !important;
  font-size: 1.2em;
  border-radius: 0;
}

.tab-buttons .button:hover {
  box-shadow: none !important;
}

.tab-buttons .button:not(.active) {
  opacity: 0.5;
}

.tab-buttons .button.active {
  background: #3d3d3dc4;
}

.plexResults {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 1em;
  border-radius: 5px;
  background: linear-gradient(
    180deg,
    rgb(7, 7, 7) 0%,
    rgb(14, 14, 14) 50%,
    rgb(14, 14, 14) 100%
  );
  width: 100%;
  border: 1px solid #2c2c2c2f;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0 0 5px 1px #000000c4;
}

.plexRequests {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  border-radius: 5px;
  background: linear-gradient(
    180deg,
    rgb(46, 46, 46) 0%,
    rgb(46, 46, 46) 50%,
    rgb(19, 19, 19) 100%
  );
  width: 100%;
  border: 1px solid #2e2e2e88;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0 0 5px 1px #000000c4;
}

.resultYear {
  font-size: 1rem;
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.resultGenres {
  font-weight: 400;
  font-style: italic;
  color: #e5a10d8a;
}

.imdbSearch {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 1em 0;
}

.imdbSearch input[type="text"] {
  padding: 1em !important;
}

.plexPoster {
  width: 122px;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.plexPoster.more {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.plexPosterRecentAdded {
  width: 122px;
  border-radius: 5px;
  object-fit: contain;
  object-position: top;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
}



.year {
  font-weight: 200;
  font-size: 1.2rem;
}

.ratingRuntime {
  display: flex;
  flex-direction: row;
  gap: 2em;
  align-items: center;
}

.rated {
  background: #ffffffcb;
  color: #000;
  font-weight: 900;
  padding: 0.2em 0.5em;
  border-radius: 5px;
  width: fit-content;
}

.genres {
  font-weight: 500;
  font-size: 1rem;
}

.awards {
  font-weight: 300;
  font-style: italic;
  font-size: 0.9rem;
}

.plot {
  font-weight: 300;
  font-size: 1rem;
  padding: 1em 0;
  border-top: 1px dotted #ffffff2f;
  text-indent: 2em;
}

.director > span {
  font-weight: 700;
}

.writer > span {
  font-weight: 700;
}

.actors {
  font-weight: 700;
}

.detailsHeader {
  font-weight: 900;
  font-size: 1.2rem;
}

.plexPosterDetails {
  display: flex;
  flex-direction: row;
  gap: 1em;
  width: 100%;
  height: 100%;
}

.plexRequestDetails {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
}

.adminButtons {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}

.adminActions {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.requestUnfulfill{
  color: #747474dc;

}

.requestFulfill {
  color: #747474dc;

}

.requestReject {
  color: #747474dc;
}

.requestDelete {
  background: #000000c2;
  color: #aa2727bb;
}

.plexDetails {
  display: flex;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  gap: 1em;
  justify-content: flex-start;
}

.plexMoreDetails {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  white-space: nowrap;
  align-items: center;
  width: 100%;
}

.plexSelector {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
}

.boxOffice {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.boxOffice > div {
  font-weight: 700;
}

.rating {
  font-size: 1rem;
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.rating > i {
  font-size: 2rem;
}

.plexButtons {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: stretch;
  width: 100%;
}

.plexResults.searchPage {
  align-items: center;
}
.plexResults.recentPage {
    background: #020202;
    flex-direction: column;
}

.searchButtons {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
}

input[type="text"].searchYear {
  max-width: 200px;
  font-size: 12px;
  font-family: "Source Code Pro", monospace;
}

.searchButtons .button {
  width: 100%;
}

input[type="text"],
input[type="password"] {
  font-size: 1rem;
  font-family: "Source Code Pro", monospace;
}

.resultsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1em;
}
.recentGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 1em;
}
.requestsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 10px;
  margin-bottom: 3em;
}
.resultTitle {
  font-size: 1.1rem;
  font-weight: 200;
  color: #fff;
  white-space: break-spaces;
}

.resultPlot {
  font-size: .9rem;
  font-weight: 300;
  max-height: 100px;
  overflow-y: scroll;
}

.resultsInfo {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: stretch;
  width: 100%;
  justify-content: space-between;
  height: 100%;
}

.recentInfo {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.requestInfo {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.requestTitle {
  /* max width ellispsis text */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 222px;
  font-weight: 600;
  color: #bdbdbd77;
}
.requestBottom {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
}

.requestStatus {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
}

.requestStatus > span {
  font-weight: 300;
}

.status.fulfilled {
  color: #6bc28c;
}
.status.pending {
  color: #b3b44c;
}
.status.rejected {
  color: #c26b6b;
}

.requestYear {
  font-weight: 500;
  font-size: 0.8rem;
  font-style: italic;
  color: #bdbdbd41;
}

.recentlyAddedContainer {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.plexHeader {
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 1em;
  margin-bottom: 5px;
  color: #e5a10d81;
  border-bottom: 1px dotted #e5a10d81;
  padding-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;
}

.plexRequestHeader, .plexRecentHeader {
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #e5a10d81;
  /* border-bottom: 1px dotted #e5a10d81; */
  padding-bottom: 0.25em;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  justify-content: space-between;
  align-items: center;
}

.plexRecentHeader {
  margin-top: 3em;
}

.plexPosterDetailsRecent {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: flex-start;
  width: 100%;
}

.searchFlex {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

.inLibrary {
  background: #09ff0031;
  color: #00ff00;
  border: 1px solid #00ff00;
  padding: 2px 5px;
  font-size: 0.9rem;
  border-radius: 5px;
  white-space: nowrap;
  width: fit-content;
  user-select: none;
}

.button.inLibraryBtn {
  background: #09ff0049;
  color: #00ff00;
  border: 1px solid #00ff00;
  font-size: 0.9rem;
  border-radius: 5px;
  white-space: nowrap;
  padding: 0.8em 1em;
}

.titleYear {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: flex-start;
}

.watchOnPlex {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
  justify-content: center;
  font-size: 1rem !important;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadingContainer {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading {
  border: 8px solid #000000;
  border-radius: 50%;
  border-top: 8px solid #e5a00d;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

.loadingText {
  font-size: 1rem;
  font-weight: 700;
  color: #e5a00d;
}